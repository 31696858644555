import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { storeToRefs } from 'pinia';
import { useRolesStore } from '~/stores';
import { computed } from 'vue';

export const QaAutoErrorRoute: RouteRecordRaw = {
	path: 'error',
	name: RouteKeys.QA_AUTO_FOLDER_ERROR,
	components: {
		default: () => import('./QaAutoGenerationErrorView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isNavEntry: false,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'qa.auto.navigation',
		requiresAuth: true
	}
};
export const QaAutoCronRoute: RouteRecordRaw = {
	path: 'cron/:qaAutoCronId',
	name: RouteKeys.QA_AUTO_CRON,
	components: {
		default: () => import('./QaAutoCronView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isNavEntry: false,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'qa.auto.navigation',
		requiresAuth: true
	}
};

export const QaAutoRoute: RouteRecordRaw = {
	path: 'auto',
	name: RouteKeys.QA_AUTO_HOME,
	components: {
		default: () => import('./QaAutoView.vue'),
		...defaultLayoutComponents
	},
	children: [QaAutoErrorRoute, QaAutoCronRoute],
	meta: {
		isSubEntry: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'qa.auto.navigation',
		requiresAuth: true,
		order: 3
	}
};

