import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';

import { debug } from '@silae/helpers';
import { useGuardedRoutes } from '~/composables';
import { useAuthenticationStore, useRolesStore } from '~/stores';

import { routes } from './routes';
import { RouteKeys } from './routes.domain';
import { storeToRefs } from 'pinia';
import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addPageView } from '~/api/metric';


export enum RedirectionCauseEnum {
	USER_NOT_AUTHENTICATED,
	FORBIDDEN
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	debug('routing', { to, from });
	const authenticationStore = useAuthenticationStore();
	const { isForbidden } = useGuardedRoutes();
	const { isAdmin, isUser, isPM, isQA } = storeToRefs(useRolesStore());

	const isAuthenticationRequired = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (to.meta.requiresAuth && !authenticationStore.isAuthenticated) {
				const principal = await lastValueFrom(
					authenticationStore.refreshPrincipal$().pipe(
						catchError(err => {
							console.log('error', err);
							return of(null);
						})
					)
				);
				if (principal != null) {
					resolve(true);
				} else {
					reject(RedirectionCauseEnum.USER_NOT_AUTHENTICATED);
				}
			} else {
				resolve(true);
			}
		});
	};

	const isAllowed = (to: RouteLocationNormalized) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (!isForbidden(to)) {
				resolve(true);
			} else {
				reject(RedirectionCauseEnum.FORBIDDEN);
			}
		});
	};

	try {
		await isAuthenticationRequired(to);
		await isAllowed(to);
		next();
	} catch (error) {
		switch (error as RedirectionCauseEnum) {
			case RedirectionCauseEnum.USER_NOT_AUTHENTICATED:
				next({ name: RouteKeys.SIGN_IN });
				break;
			case RedirectionCauseEnum.FORBIDDEN:
				if (isPM.value) {
					next({ name: RouteKeys.PM_REQUIREMENTS_TABLE });
				} else if (isUser.value) {
					next({ name: RouteKeys.PAY_GEN });
				} else if (isQA.value) {
					next({ name: RouteKeys.QA2 });
				} else if (isAdmin.value) {
					next({ name: RouteKeys.CONFIG });
				} else {
					next({ name: RouteKeys.HOME });
				}
				break;
		}
	}
});

router.afterEach((to, from, failure) => {
	if (!failure) {
		addPageView(to.name as string).subscribe();
	}
});

export { router };
