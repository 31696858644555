import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { computed, ComputedRef, Ref, ref } from 'vue';

import {
	AuthenticateRequestDTO,
	B2CIdTokenDTO,
	b2cRefreshHeadless$,
	B2CTokenDTO,
	fetchPrincipal$,
	parseJwt,
	QA2Right,
	signInB2C$,
	signInToBackend$,
	signOutFromB2C$,
	signOutFromBackend$,
	UserDTO
} from '~/api';
import { PrettyUser } from '~/domain';
import { Clearable } from '~/stores/store.domain';
import { prettyEmployeeName } from '~/utils';
import { DateTime } from 'luxon';

export type AuthenticationStore = Clearable & {
	accessTokenExpirationTime: ComputedRef<number>;
	authenticateUsingHeadlessB2C$: (request: AuthenticateRequestDTO) => Observable<UserDTO | IsImpersonator>;
	continueB2CAuth: () => Observable<UserDTO>;
	continueB2CJwt: (jwt: string) => Observable<UserDTO>;
	getStorageKey: (suffix: string) => string;
	isAuthenticated: ComputedRef<boolean>;
	userRights: ComputedRef<QA2Right[]>;
	principal: ComputedRef<PrettyUser>;
	refreshPrincipal$: () => Observable<UserDTO>;
	refreshAuthUsingHeadlessB2C$: () => Observable<UserDTO | IsImpersonator>;
	signOut$: () => Observable<void>;
};

function isImpersonatorToken(auth: B2CTokenDTO) {
	const token = parseJwt(auth.id_token) as B2CIdTokenDTO;
	return token.impersonator;
}

export interface IsImpersonator {
	hasImpersonateRight: boolean;
}

interface Impersonator {
	login: string;
	password: string;
	access_token: string;
	token_type: string;
	expires_in: string;
	refresh_token: string;
	id_token: string;
}

const refreshTokenKey = `${import.meta.env.QA2_ENVIRONMENT}:rt`;

export const useAuthenticationStore = defineStore<'authentication-store', AuthenticationStore>('authentication-store', () => {
	const _principal: Ref<PrettyUser> = ref(null);
	const _impersonator: Ref<Impersonator> = ref(null);
	const _storageKey = computed(() => btoa(_principal.value?.login));
	const isAuthenticated = computed(() => _principal.value != null);
	const _userRights = ref<QA2Right[]>([]);
	const _accessTokenExpirationTime: Ref<number> = ref(null);
	const accessTokenExpirationTime = computed(() => _accessTokenExpirationTime.value);

	const clear = () => {
		_principal.value = null;
		_userRights.value = [];
		localStorage.removeItem(refreshTokenKey);
	};

	const setPrincipal = (principal: UserDTO) => {
		const fullname = prettyEmployeeName(principal.firstname, principal.lastname);
		if (principal.rights === undefined) {
			_userRights.value = [];
		} else {
			_userRights.value = principal.rights;
		}

		_principal.value = { ...principal, fullname };
	};

	const refreshPrincipal$ = () => {
		return fetchPrincipal$().pipe(
			catchError(err => {
				console.log('Error while refreshing principal', err);
				if (getRefreshToken()) {
					return refreshAuthUsingHeadlessB2C$();
				} else {
					return throwError(() => err);
				}
			}),
			tap(setPrincipal)
		);
	};

	const authUsingB2CToken = (b2cIdToken: string) => signInToBackend$(b2cIdToken).pipe(tap(principal => setPrincipal(principal)));


	const getRefreshToken = () => localStorage.getItem(refreshTokenKey);
	const storeRefreshToken = (auth: B2CTokenDTO) => localStorage.setItem(refreshTokenKey, auth.refresh_token);

	const setAccessTokenExpirationTime = (b2cTokens: B2CTokenDTO) =>
		(_accessTokenExpirationTime.value = DateTime.now()
			.plus({ minutes: 15 })
			.toMillis());

	// get token hint from backend and use it to get B2C state using a redirect as B2C forbid to use an HTTP call
	const authenticateUsingHeadlessB2C$ = (request: AuthenticateRequestDTO) =>
		signInB2C$(request).pipe(
			tap(b2cTokens => {
				storeRefreshToken(b2cTokens);
				setAccessTokenExpirationTime(b2cTokens);
			}),
			switchMap((auth: B2CTokenDTO) => {
				if (isImpersonatorToken(auth)) {
					_impersonator.value = { ...request, ...auth };
					return of({ hasImpersonateRight: true } as IsImpersonator);
				} else {
					return authUsingB2CToken(auth.id_token);
				}
			})
		);

	const refreshAuthUsingHeadlessB2C$: () => Observable<UserDTO> = () =>
		of(getRefreshToken()).pipe(
			switchMap(refreshToken => b2cRefreshHeadless$(refreshToken)),
			tap(b2cTokens => {
				storeRefreshToken(b2cTokens);
				setAccessTokenExpirationTime(b2cTokens);
			}),
			switchMap((auth: B2CTokenDTO) => {
				return authUsingB2CToken(auth.id_token);
			})
		);

	const continueB2CAuth = () => authUsingB2CToken(_impersonator.value.id_token);
	const continueB2CJwt = (jwt: string) => authUsingB2CToken(jwt);

	const signOut$ = () =>
		signOutFromBackend$().pipe(
			switchMap(() => {
				_principal.value = null;
				_userRights.value = [];
				return signOutFromB2C$();
			})
		);

	const getStorageKey = (suffix: string) => {
		if (!isAuthenticated.value) {
			return;
		}

		return `${_storageKey.value}-${suffix}`;
	};

	return {
		clear,
		accessTokenExpirationTime,
		authenticateUsingHeadlessB2C$,
		continueB2CAuth,
		continueB2CJwt,
		getStorageKey,
		isAuthenticated,
		principal: computed(() => _principal.value),
		userRights: computed(() => _userRights.value),
		refreshPrincipal$,
		refreshAuthUsingHeadlessB2C$,
		signOut$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthenticationStore, import.meta.hot));
