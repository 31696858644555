import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const AzureSprintListRoute: RouteRecordRaw = {
	path: 'azure-sprints',
	name: RouteKeys.PM_AZURE_SPRINT,
	components: {
		default: () => import('./AzureSprintListView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'pm.azureSprint.navigation',
		isSubEntry: true,
		order: 41
	}
};

export const AzureSprintDetailsRoutes: RouteRecordRaw = {
	path: 'azure-sprint/:sprintRunId',
	name: RouteKeys.PM_AZURE_SPRINT_DETAILS,
	components: {
		default: () => import('./AzureSprintView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'pm.azureSprint.navigation',
		isNavEntry: false,
		isSubEntry: false,
		order: 41
	}
};


export const AzureSprintRoutes: RouteRecordRaw[] = [AzureSprintListRoute, AzureSprintDetailsRoutes];
