import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';
import { defaultLayoutComponents } from '~/pages/layout';


export const QaConfigPatternRoute: RouteRecordRaw = {
	path: ':patternId',
	name: RouteKeys.QA2_CONFIG_PATTERN,
	components: {
		default: () => import('./QaAutoConfig.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		}
	}
};

export const QaConfigRoute: RouteRecordRaw = {
	path: 'config',
	name: RouteKeys.QA2_CONFIG,
	components: {
		default: () => import('./QaAutoConfig.vue'),
		...defaultLayoutComponents
	},
	children: [QaConfigPatternRoute],
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		}
	}
};
