import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';


export const qaTestCampaignHomeRoute = {
	path: 'test-campaign',
	name: RouteKeys.QA_TEST_CAMPAIGN,
	components: {
		default: () => import('./TestCampaignView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'qa.testCampaign.navigation',
		isSubEntry: true,
		order: 6
	}
};

