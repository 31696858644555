import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const SupportRoutes: RouteRecordRaw = {
	path: '/support',
	name: RouteKeys.SUPPORT,
	components: {
		default: () => import('./SupportView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isSupport } = storeToRefs(useRolesStore());
			return computed(() => !isSupport.value);
		},
		label: 'support.navigation',
		icon: 'hospital-user',
		isNavEntry: true,
		order: 50
	}
};
