import { RouteRecordRaw } from 'vue-router';

import { AccountRoute } from '~/pages/account/account.route';
import { ConfigRoute } from '~/pages/config/config-routes';
import { PayGenRoute } from '~/pages/paiegen/pay.gen-routes';
import { ConnectionRoute, SignInRoute } from '~/pages/sign-in/sign-in.route';
import { AnalysisItemsRoutes } from '~/pages/pm/statistics/statistics-routes';
import { RouteKeys } from '~/router/routes.domain';
import { MetricRoute } from '~/pages/metric/metric-routes';
import { HomeRoute } from '~/pages/home/home-routes';
import { requirementFromRulesRoutes, requirementRoutes } from '~/pages/pm/requirement/requirement-routes';
import { SupportRoutes } from '~/pages/support/support-routes';
import { PMRoute } from '~/pages/pm/pm-routes';
import { QaHomeRoute } from '~/pages/qa/qa-routes';

const FallbackRoute: RouteRecordRaw = {
	name: RouteKeys.NOT_FOUND,
	path: '/:pathMatch(.*)*',
	redirect: HomeRoute,
	meta: {
		requiresAuth: true
	}
};

export const routes = [
	HomeRoute,
	PayGenRoute,
	PMRoute,
	AnalysisItemsRoutes,
	QaHomeRoute,
	ConnectionRoute,
	requirementRoutes,
	requirementFromRulesRoutes,
	SignInRoute,
	ConfigRoute,
	MetricRoute,
	AccountRoute,
	SupportRoutes,
	FallbackRoute
];
