import { ListEntry } from '@silae/helpers';

export type TestResultPatternDTO = {
	id?: number;
	parentId?: number;
	name: string;
	pattern: string;
	function: string;
	standalone: boolean;
};

export type MethodParameterDTO = {
	name: string;
	type: string;
}

export type MethodDTO = {
	value: string;
	label: string;
	className: string;
	parameters: MethodParameterDTO[];
}
/*
 * user dto reference date based on folder reference mont period
 */
export type ReferencedDate = {
	reference: string;
	/**
	 * number of month before or after reference.
	 * not used if reference is inPast or inFuture
	 */
	value: number;
}

export type DateFormat = {
	type: string,
	managerReference: string,
}

export type FolderFormat = {
	cleanLeave: boolean,
	reference: string,
	modules: string[]
}

export type LeaveFormat = {
	reference: string,
	startDate: ReferencedDate;
	endDate: ReferencedDate | undefined;
	period: string;
	periodQuantity: number;//Only for FULL_DAYS and HOURS
	employeeReference: string | undefined;
	validatorReference: string | undefined;
	state: string | undefined;
}

export type PrerequisiteFolderFormat = {
	leaves: LeaveFormat[];
	users: UserFormat[];
	folderFormats: FolderFormat;
}

export type PrerequisiteFolders = {
	format: string;
	folders: PrerequisiteFolderFormat[];
	multiFolderUserFormats: MultiFolderUserFormat[];
}

export type MultiFolderUserFormat = {
	userReferences: Record<number, string>
};

export enum EmployeeRole {
	EMPLOYEE = 'EMPLOYEE',
	MANAGER = 'MANAGER',
	ADMIN = 'ADMIN',

}

export type FolderRole = {
	folderReference: string;
	roles: EmployeeRole[];
}

export type UserFormat = {
	folderReferences: FolderRole[]
	reference: string,
	managerReference: string | undefined,
	details: UserFormatDetails | undefined,
	employee: boolean;
	contact: boolean;
	adminRef: boolean;
}

export type UserFormatDetails = {
	firstName: string | undefined,
	lastName: string | undefined,
	contractType: string | undefined,
	endContractDate: ReferencedDate | undefined;
	startContractDate: ReferencedDate | undefined;
}


export const referenceListEntries: ListEntry[] = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' },
	{ value: 'E', label: 'E' },
	{ value: 'F', label: 'F' },
	{ value: 'G', label: 'G' },
	{ value: 'H', label: 'H' },
	{ value: 'I', label: 'I' },
	{ value: 'J', label: 'J' }
];
export const referenceManagerListEntries: ListEntry[] = [
	...referenceListEntries,
	{ value: undefined, label: 'Pas de manager' }
];

export type PrerequisiteFormatDTO = {
	leaves: LeaveFormat[];
	users: UserFormat[];
	folders: FolderFormat[];
}

export type STTestCaseExecution = {
	id: number | undefined;
	testCampaignId: number;
	name: string;
	prerequisite: string;
	result: string;
	resultComment: string;
	testCaseId: number;
	executionDuration: number;
}

export type STTestStepExecution = {
	id: number | undefined;
	testCaseId: number;
	testCaseExecutionId: number;
	testStepId: number;
	action: string;
	expectedResult: string;
	stepIndex: number;
	result: string;
	resultComment: string;
}
export type STTestCampaign = {
	id: number | undefined;
	folderId: number;
	name: string;
	startExecutionDate: Date | undefined;
	lastExecutionDate: Date | undefined;
}


