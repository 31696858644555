import { AxiosError } from 'axios';
import { Observable } from 'rxjs';
import { PaginationData, useBackendHttpService } from '~/api/http';

export const ABOUT_URL = '/v1/about';
export const SQUASH_URL = '/v1/squash';
export const PAIE_SQL_URL = '/v1/paie-sql';
export const STATISTICS_URL = '/v1/statistics';
export const PAIE_GENERATOR_URL = '/v1/paie-generator';
export const TASK_URL = '/v1/task';
export const PG_COMPANY_URL = `${PAIE_GENERATOR_URL}/company`;
export const PG_ESTABLISHMENT_URL = `${PAIE_GENERATOR_URL}/establishment`;
export const PG_EMPLOYEE_URL = `${PAIE_GENERATOR_URL}/employee`;
export const PG_CONTRACT_URL = `${PAIE_GENERATOR_URL}/contract`;
export const PG_LEAVE_URL = `${PAIE_GENERATOR_URL}/leave`;
export const PG_TEAM_URL = `${PAIE_GENERATOR_URL}/team`;
export const PG_MULTI_GEN_URL = `${PAIE_GENERATOR_URL}/multi-generation`;
export const PG_MGI_URL = `${PG_MULTI_GEN_URL}/item`;
export const PG_MGCI_URL = `${PG_MULTI_GEN_URL}/company-item`;
export const AZURE_URL = '/v1/azure';
export const QA2_FORMAT_URL = '/v1/qa2/format';
export const QA2_FORMAT_PATTERN_URL = `${QA2_FORMAT_URL}/pattern`;
export const QA_AUTO = `/v1/qa-auto`;
export const QA_AUTO_ERROR = `/v1/qa-auto/error`;
export const QA_URL = `/v1/qa`;
export const QA_TEST_CASE_EXECUTION_URL = `${QA_URL}/test-case-execution`;
export const QA_TEST_STEP_EXECUTION_URL = `${QA_URL}/test-step-execution`;
export const QA_TEST_CAMPAIGN_URL = `${QA_URL}/test-campaign`;
export const GEO_URL = '/v1/geo';
export const KPI_URL = '/v1/kpi';
export const REQUIREMENT_URL = '/v1/requirement';
export const SUPPORT_URL = '/v1/support';
export const METRIC_URL = '/v1/metric';

export const ACCOUNT_URL = '/v1/account';
export const CONFIG_URL = '/v1/config';
export const CONFIG_USER_URL = `${CONFIG_URL}/user`;
export const CONFIG_USER_GROUP_URL = `${CONFIG_URL}/user-group`;
export const SILAE_PAIE_URL = `${CONFIG_URL}/silae-pf`;
export const CONFIG_PASSWORD_URL = `${CONFIG_URL}/password`;
export const SYSTEM_URL = '/v1/system';
export const CHECK_PATTERN_URL = '/v1/check-pattern';
export const PM_AZURE_SPRINT = '/v1/pm/azure/sprint';
export const PM_AZURE_SPRINT_RUN = `${PM_AZURE_SPRINT}/fetch-run`;

export type ApiError<T = unknown> = {
	context?: T;
	error: string;
	errorCode: string;
	path: string;
	status: number;
	timestamp: number;
};

export type AxiosApiError<T = unknown> = AxiosError<ApiError<T>>;

// format = "yyyy-MM-dd"
export type ISODateString = string;

// format = "yyyy-MM-ddThh:mm:ss"
export type ISODateTimeString = string;

export type SortItem = {
	key: string;
	order?: boolean | 'asc' | 'desc';
};

export type DefaultApi<T> = {
	getPage: (page: number, size: number, sort?: string) => Observable<PaginationData<T>>
	getById: (id: number) => Observable<T>,
	create: (data: T) => Observable<T>,
	update: (data: T) => Observable<T>,
	deleteObject: (id: number) => Observable<void>,
}

export function useDefaultApi<T>(baseUrl: string): DefaultApi<T> {
	return {
		getPage: (page: number, size: number, sort: string | undefined = undefined) => {
			const { http } = useBackendHttpService();
			if (sort) {
				return http.getPage<T>(`${baseUrl}/all?sort=${sort}`, page, size);
			} else {
				return http.getPage<T>(`${baseUrl}/all?sort=id,asc`, page, size);
			}

		},
		getById: (id: number) => {
			const { http } = useBackendHttpService();
			return http.get<T>(`${baseUrl}/${id}`);
		},
		create: (data: T) => {
			const { http } = useBackendHttpService();
			return http.post<T>(`${baseUrl}`, data);
		},
		update: (data: T) => {
			const { http } = useBackendHttpService();
			return http.put<T>(`${baseUrl}`, data);
		},
		deleteObject: (id: number) => {
			const { http } = useBackendHttpService();
			return http.delete(`${baseUrl}/${id}`);
		}
	};
}
