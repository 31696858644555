// @ts-expect-error: FontAwesome custom packaging types are not correctly generated
import { byPrefixAndName } from '@awesome.me/kit-b14e749b71/icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faAlienMonster,
	faArrowDownToLine,
	faArrowLeftLongToLine,
	faArrowRightArrowLeft,
	faArrowRightLongToLine,
	faArrowUpRightFromSquare,
	faBackwardFast,
	faBackwardStep,
	faBars,
	faBomb,
	faBoxesStacked,
	faBuilding,
	faCalendar,
	faCalendarDay,
	faCheck,
	faChevronDown,
	faChevronUp,
	faCircle,
	faCircleCheck,
	faCircleEllipsis,
	faCircleEuro,
	faCircleInfo,
	faCirclePlus,
	faCircleQuestion,
	faClock,
	faClose,
	faCoins,
	faCompassDrafting,
	faCopy,
	faDownload,
	faEllipsisV as faEllipsisVertical,
	faEye,
	faEyeSlash,
	faFile,
	faFileDoc,
	faFileExcel,
	faFileImage,
	faFileImport,
	faFileWord,
	faFilter,
	faFloppyDisk,
	faFolder,
	faFolderOpen,
	faForwardFast,
	faForwardStep,
	faGears,
	faGhost,
	faHorizontalRule,
	faHospitalUser,
	faHouseChimney,
	faInfoCircle,
	faListCheck,
	faListTree,
	faMagnifyingGlass,
	faMinus,
	faMoon,
	faPen,
	faPenRuler,
	faPeriod,
	faPerson,
	faPlus,
	faPowerOff,
	faRotate,
	faRotateLeft,
	faSearch,
	faServer,
	faSort,
	faSpinner,
	faSpinnerThird,
	faSquare,
	faSquareCaretLeft,
	faStar as faRatingEmpty,
	faStarHalf,
	faSunBright,
	faToggleOff,
	faToggleOn,
	faTrashCan,
	faTriangleExclamation,
	faUmbrellaBeach,
	faUser,
	faUserGraduate,
	faUserGroup,
	faUsers,
	faUsersLine,
	faUsersViewfinder,
	faWandMagicSparkles,
	faXmark
} from '@fortawesome/pro-light-svg-icons';
import {
	faBuildingUser,
	faChartColumn,
	faCheckDouble,
	faCircleDot as faRadioOn,
	faCircleExclamation,
	faPencil,
	faRobot,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faUserPlus,
	faVialCircleCheck
} from '@fortawesome/pro-solid-svg-icons';

export const ICON_LIBRARY: { [keys: string]: IconDefinition } = {
	faGears,
	faUsersViewfinder,
	//faAddCircle: byPrefixAndName.fak['add-circle'],
	faAlienMonster,
	//faArrowBendLeftDown: byPrefixAndName.fak['arrow-bend-left-down'],
	faArrowDown: faChevronDown,
	faArrowDownToLine,
	faArrowLeft: byPrefixAndName.fak['arrow-left'],
	faArrowLeftLongToLine,
	faFilter,
	faSearch,
	faArrowRight: byPrefixAndName.fak['arrow-right'],
	faArrowRightLongToLine,
	faArrowRightArrowLeft,
	faArrowUp: faChevronUp,
	faArrowUpRightFromSquare,
	faUserGroup,
	faCalendar,
	faBin: faCoins,
	faClose: faXmark,
	faCancel: faXmark,
	faRemoveFull: faTrashCan,
	faDownload,
	faHide: faEyeSlash,
	faFiles: faFileDoc,
	faImport: faFileImport,
	faInfoFull: faInfoCircle,
	feMeeting: faUserGroup,
	faMenu: faBars,
	faMeeting: faUsersLine,
	faShow: faEye,
	faDownArrow: faChevronDown,
	faUpArrow: faChevronUp,
	faValidateFull: faCheck,
	faWarningFull: faTriangleExclamation,
	faIdentity: faUser,
	faLogOut: faPowerOff,
	faHelp: faCircleQuestion,
	//faAssignGroup: byPrefixAndName.fak['asign-group'],
	//faAvatar: byPrefixAndName.fak['avatar'],
	//faBackArrow: byPrefixAndName.fak['back-arrow'],
	faBomb,
	faListCheck,
	faBackwardFast,
	faBackwardStep,
	faBoxesStacked,
	faBuildingUser,
	faBuilding,
	//faBagage: byPrefixAndName.fak['bagage'],
	//faBilling: byPrefixAndName.fak['billling'],
	//faBin: byPrefixAndName.fak['bin'],
	//faCancel: byPrefixAndName.fak['cancel'],
	//faCalendar: byPrefixAndName.fak['calendar'],
	//faCalendarAdd: byPrefixAndName.fak['calendar-add'],
	faCalendarDay,
	faCardClose: faClose,
	//faCardCryptogram: byPrefixAndName.fak['card-cryptogram'],
	faCardOpen: faSquareCaretLeft,
	faCopy,
	faChartColumn,
	//faChat: byPrefixAndName.fak['chat'],
	faCheck,
	faCheckDouble,
	//faChecklist: byPrefixAndName.fak['checklist'],
	faChevronDown,
	//faChevronLeft: byPrefixAndName.fak['arrow-left'],
	//faChevronsLeft: byPrefixAndName.fak['double-arrow-left'],
	//faChevronRight: byPrefixAndName.fak['arrow-right'],
	//faChevronsRight: byPrefixAndName.fak['double-arrow-right'],
	faChevronUp,
	faCircle,
	faCircleCheck,
	faCircleEllipsis,
	faCircleExclamation,
	faCircleInfo,
	faCircleEuro,
	faCirclePlus,
	faCompassDrafting,
	faClock,
	//faClose: byPrefixAndName.fak['close'],
	//faCompany: byPrefixAndName.fak['company'],
	//faConfiguration: byPrefixAndName.fak['configuration'],
	//faContact: byPrefixAndName.fak['contact'],
	//faContract: byPrefixAndName.fak['contract'],
	//faDashboard: byPrefixAndName.fak['dashboard'],
	//faDesktop: byPrefixAndName.fak['desktop'],
	//faDocument: byPrefixAndName.fak['document'],
	//faDot: byPrefixAndName.fak['dot'],
	//faDownArrow: byPrefixAndName.fak['down-arrow'],
	//faDownload: byPrefixAndName.fak['download'],
	//faDownloadOnlyOne: byPrefixAndName.fak['download-only-one'],
	//faDownloadSeveral: byPrefixAndName.fak['download-several'],
	faEllipsisVertical,
	faEye,
	faPenRuler,
	faHospitalUser,
	//faExpand: byPrefixAndName.fak['expand'],
	//faFamily: byPrefixAndName.fak['family'],
	//faFavorite: byPrefixAndName.fak['favorite'],
	faFile,
	faMenuDots: faEllipsisVertical,
	//faFiles: byPrefixAndName.fak['files'],
	faFileDoc,
	faFileExcel,
	faFileImage,
	//faFilePdf: byPrefixAndName.fak['pdf'],
	faFileWord,
	faFolder,
	faFolderOpen,
	faForwardFast,
	faForwardStep,
	faFloppyDisk,
	//faGeolocation: byPrefixAndName.fak['geolocation'],
	faGhost,
	//faGraduation: byPrefixAndName.fak['graduation'],
	//faGroupAvatar: byPrefixAndName.fak['group-avatar'],
	//faHelp: byPrefixAndName.fak['help'],
	//faHealth: byPrefixAndName.fak['health'],
	//faHide: byPrefixAndName.fak['hide'],
	faHorizontalRule,
	//faHourglass: byPrefixAndName.fak['hourglass'],
	faHouseChimney,
	//faHome: byPrefixAndName.fak['home'],
	//faIdentity: byPrefixAndName.fak['identity'],
	//faImport: byPrefixAndName.fak['import'],
	//faInfo: byPrefixAndName.fak['info'],
	//faInfoFull: byPrefixAndName.fak['info-full'],
	faKebabMenu: faBars,
	//faLanguage: byPrefixAndName.fak['language'],
	//faLegale: byPrefixAndName.fak['legale'],
	//faLegend: byPrefixAndName.fak['legend'],
	//faListBullets: byPrefixAndName.fak['list-bullets'],
	//faListCheck: byPrefixAndName.fak['list-checks'],
	faListTree,
	//faLogOut: byPrefixAndName.fak['log-out'],
	faMagnifyingGlass,
	//faMeeting: byPrefixAndName.fak['meeting'],
	//faMenu: byPrefixAndName.fak['menu'],
	faMinus,
	faMoon,
	//faNoGroup: byPrefixAndName.fak['no-group'],
	//faPayrollCycle: byPrefixAndName.fak['payroll-cycle'],
	//faPadlockClose: byPrefixAndName.fak['padlock-close'],
	faPen,
	faPencil,
	faPeriod,
	faPerson,
	faPlus,
	faPowerOff,
	//faRh: byPrefixAndName.fak['rh'],
	//faRoleAdmin: byPrefixAndName.fak['role-admin'],
	//faRoleEmployee: byPrefixAndName.fak['role-employee'],
	//faRoleManager: byPrefixAndName.fak['role-manager'],
	faRobot,
	faRadioOn,
	faRatingEmpty,
	//faRefresh: byPrefixAndName.fak['refresh'],
	//faRemove: byPrefixAndName.fak['remove'],
	//faRemoveFull: byPrefixAndName.fak['remove-full'],
	//faRest: byPrefixAndName.fak['rest'],
	faRotate,
	faRotateLeft,
	//faSearch: byPrefixAndName.fak['search'],
	faServer,
	//faSetting: byPrefixAndName.fak['setting'],
	//faShow: byPrefixAndName.fak['show'],
	faSort,
	//faSortDown: byPrefixAndName.fak['sort-down'],
	//faSortOut: byPrefixAndName.fak['sort-out'],
	//faSortUp: byPrefixAndName.fak['sort-up'],
	faSpinnerThird,
	faSpinner,
	faSquare,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faStarHalf,
	faSunBright,
	faToggleOff,
	faToggleOn,
	faTrashCan,
	faTriangleExclamation,
	//faTwoAvatar: byPrefixAndName.fak['two-avatar'],
	faUmbrellaBeach,
	//faUnemployment: byPrefixAndName.fak['unemployment'],
	//faUpArrow: byPrefixAndName.fak['up-arrow'],
	faUser,
	faUserPlus,
	faUserGraduate,
	faUsers,
	//faValidate: byPrefixAndName.fak['validate'],
	//faValidateFull: byPrefixAndName.fak['validate-full'],
	faVialCircleCheck,
	faWandMagicSparkles,
	//faWarning: byPrefixAndName.fak['warning'],
	//faWarningFull: byPrefixAndName.fak['warning-full'],
	faXmark
};
