<template>
	<v-text-field
		v-model="model"
		:color="color"
		:density="density"
		:placeholder="$t('common.components.search_input.placeholder')"
		class="search-input"
		clearable
		hide-details
		rounded
		variant="outlined"
		@click:clear="clear"
	>
		<template #prepend-inner>
			<fa-icon color="secondary" icon="search" size="sm" />
		</template>
	</v-text-field>
</template>

<style scoped>
.search-input {
	min-width: v-bind(minWidth);
}
</style>

<script lang="ts" setup>
import { VTextField } from 'vuetify/components';

import { useModelValue } from '@silae/composables';
import { UiDensity } from '@silae/helpers';

import { FaIcon } from '../../icons';

const props = withDefaults(
	defineProps<{
		minWidth?: string;
		modelValue?: string;
		color?: string;
		density?: UiDensity;
	}>(),
	{
		minWidth: '210px',
		color: 'secondary',
		density: UiDensity.COMFORTABLE
	}
);

const model = useModelValue(props);

const clear = () => (model.value = '');
</script>
