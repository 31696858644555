import { METRIC_URL, useBackendHttpService } from '~/api';
import { MetricRequest, MetricResponse } from '~/api/metric/metric-dto';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

export function getPageViewMetrics(request: MetricRequest) {
	const { http } = useBackendHttpService();
	return http.post<MetricResponse>(`${METRIC_URL}/page-views`, request).pipe(map(response => {
		return {
			...response,
			startTime: DateTime.fromISO(response.startTime),
			endTime: DateTime.fromISO(response.endTime),
			metrics: response.metrics.map(metric => {
				return {
					...metric,
					startTime: DateTime.fromISO(metric.startTime),
					endTime: DateTime.fromISO(metric.endTime)
				};
			})
		};
	}));
}

export function getTaskMetrics(request: MetricRequest) {
	const { http } = useBackendHttpService();
	return http.post<MetricResponse>(`${METRIC_URL}/tasks`, request);
}

export function addPageView(pageName: string) {
	const { http } = useBackendHttpService();
	return http.post(`${METRIC_URL}/add/page`, { key: pageName });
}

export function cleanPageViewMetrics() {
	const { http } = useBackendHttpService();
	return http.delete(`${METRIC_URL}/clean/pages`);
}

export function cleanTaskRunMetrics() {
	const { http } = useBackendHttpService();
	return http.delete(`${METRIC_URL}/clean/tasks`);
}
